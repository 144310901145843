/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

import { ReactComponent as CircleCheck } from "../../assets/icons/circle_check.svg";
import { Locales } from "../../../core/constants/Localize";
import { NavLink } from "react-router-dom";
import { routes } from "../../../core/routes/Routes";
import TextButton from "../Reusable/TextButton/TextButton";
import React from "react";
import "./SuccessCreate.scss";

export interface LocalProps {
  text: string;
}

const SuccessCreate = ({ text }: LocalProps) => (
  <div style={{ marginTop: "1rem" }}>
    <div className="p-fluid">
      <div>
        <CircleCheck />
      </div>
      <div className="p-field">
        <p>{text}</p>
      </div>
    </div>
    <div className="p-fluid">
      <div className="p-field">
        <NavLink className="g-link submit-container" to={routes.login.path}>
          <TextButton type="filled">{Locales.BackToLogin}</TextButton>
        </NavLink>
      </div>
    </div>
  </div>
);

export default SuccessCreate;
