/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React from "react"
import Spinner from "../Spinner/Spinner";
import "./TextButton.scss";

interface LocalProps {
  type?: "filled" | "outlined" | "link-styled";
  children: string;
  ariaLabel?: string;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  loading?: boolean;
  submit?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const TextButton = ({
  type,
  children,
  ariaLabel,
  className,
  style,
  disabled,
  loading,
  submit,
  onClick,
}: LocalProps) => {
  let classType = "";

  if (type === "link-styled") {
    classType = "g-link-button";
  } else if (type === "filled") {
    classType = "g-button primary";
  } else if (type === "outlined") {
    classType = "g-button p-button-outlined";
  }

  return (
    <button
      aria-label={ariaLabel ? ariaLabel : children}
      type={submit ? "submit" : "button"}
      className={`basic-button ${classType} ${
        disabled || loading ? "disabled" : ""
      } ${className ? className : ""}`}
      style={style}
      onClick={onClick}
      disabled={disabled || loading}
    >
      {loading && <Spinner size={16} className="g-loading-spinner" />}
      <p className={loading ? "g-button-content" : ""}>{children}</p>
    </button>
  );
};

export default TextButton;
