/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import ReactGA from 'react-ga4';
import { GaOptions, UaEventOptions } from 'react-ga4/types/ga4';
import { info } from '../utils/Debug.utils';

interface InitializerOptions {
  legacyDimensionMetric?: boolean;
  nonce?: string;
  testMode?: boolean;
  gaOptions?: GaOptions | any;
  gtagOptions?: any;
}

let isInitialized = false;
let isInDevMode = false;

const initialize = (measurementId: string, options?: InitializerOptions): void => {
  ReactGA.initialize(measurementId, options);
  isInitialized = true;
};

/**
 * In dev mode, events are not sent to GA
 */
const initializeDevMode = () => {
  isInDevMode = true;
  info('GA initialized in dev mode');
};

const pushEvent = (event: UaEventOptions): void => {
  if (isInitialized) {
    ReactGA.event(event);
  }
  if (isInDevMode) {
    info('[GA4 push event]');
    info(event);
  }
};

const set = (data: Record<string, string | number | null>): void => {
  if (isInitialized) {
    ReactGA.set(data);
  }
  if (isInDevMode) {
    info('[GA4 set]');
    info(data);
  }
};

export const analyticsClient = {
  initialize,
  initializeDevMode,
  pushEvent,
  set,
};

export enum EventCategory {
  AccountCreation = 'AccountCreation',
  Login = 'Login',
  ForgotPassword = 'ForgotPassword',
  Logout = 'Logout',
  Home = 'Home',
  Reports = 'Reports',
  UserManagement = 'UserManagement',
  RadiusSettings = 'RadiusSettings',
  Switch = 'Switch',
  Help = 'Help',
  MapView = 'MapView',
  ViewHistory = 'ViewHistory',
  Insights = 'Insights',
  Alerts = 'Alerts',
  Layers = 'Layers',
  Timelines = 'Timelines',
  Displays = 'Displays',
  SiteList = 'SiteList',
  ObservationList = 'ObservationList',
  EmissionList = 'EmissionList',
  SiteDetails = 'SiteDetails',
  MyProfile = 'MyProfile',
  MyBookmarks = 'MyBookmarks',
  TermsOfService = 'TermsOfService',
}

export const EventActions = {
  AccountCreation: {
    Submit: 'Submit',
  },
  Login: {
    Submit: 'Submit',
    Complete: 'Complete',
    Fail: 'Fail',
    Error: 'Error',
  },
  ForgotPassword: {
    Submit: 'Submit',
  },
  Logout: {
    Click: 'Click',
    Timeout: 'Timeout',
  },
  Home: {
    LoadComplete: 'LoadComplete',
  },
  Reports: {
    Open: 'Open',
    Download: 'Download',
  },
  UserManagement: {
    Open: 'Open',
    ClickCreate: 'Click-Create',
    Create: 'Create',
    ClickEdit: 'Click-Edit',
    ClickDelete: 'Click-Delete',
    Edit: 'Edit',
    Delete: 'Delete',
    ResendInvite: 'Resend-Invite',
  },
  RadiusSettings: {
    Open: 'Open',
    Save: 'Save',
  },
  Switch: {
    MapView: 'MapView',
    ListView: 'ListView',
  },
  Help: {
    Click: 'Click',
  },
  MyProfile: {
    Open: 'Open',
    Update: 'Update',
    UpdatePassword: 'Update-Password',
  },
  MyBookmarks: {
    Open: 'TopBar-Open',
    Save: 'Save',
    ClickDelete: 'Click-Delete',
    RightClickOpen: 'Right-Click-Open',
    Close: 'Close',
    AddRow: 'Add-Row',
  },
  MapView: {
    ClickZone: 'Click-Zone',
    ClickGrid: 'Click-Grid',
    ClickSite: 'Click-Site',
    ClickEmission: 'Click-Emission',
    ClickObservation: 'Click-Observation',
    ClickCatalogOffering: 'Click-Catalog-Offering',
    ClickLandmark: 'Click-Landmark',
    ClickBookmark: 'Click-Bookmark',
    ChangeWeek: 'ChangeWeek',
    Share: 'Share',
    Search: 'Search',
    OpenLegend: 'Open-Legend',
  },
  ViewHistory: {
    DeleteAllViews: 'DeleteAllViews',
    DeleteView: 'DeleteView',
    ClickSeeOnMap: 'Click-SeeOnMap',
    ClickSiteDetails: 'Click-SiteDetails',
    DownloadEmissions: 'Download-Emissions',
    DownloadObservation: 'Download-Observation',
  },
  Insights: {
    ClickSite: 'Click-Site',
  },
  Alerts: {
    ClickSite: 'Click-Site',
    ChangeTimeframe: 'Change-Timeframe',
  },
  Layers: {
    Show: 'Show',
    Hide: 'Hide',
  },
  Timelines: {
    Open: 'Open',
    ClickAllLayersWeek: 'Click-AllLayers-Week',
    ClickConcentrationWeek: 'Click-Concentration-Week',
    ClickHotspotsWeek: 'Click-Hotspots-Week',
    ClickFlaresWeek: 'Click-Flares-Week',
    ClickPredictionsWeek: 'Click-Predictions-Week',
    SelectGHGSatInterval: 'Select-GHGSat-Interval',
    SelectThirdPartyInterval: 'Select-ThirdParty-Interval',
    SelectObservationsInterval: 'Select-Observations-Interval',
    SelectCatalogInterval: 'Select-Catalog-Interval',
  },
  Displays: {
    Select: 'Select',
    Create: 'Create',
    SetDefault: 'Set-Default',
    Delete: 'Delete',
  },
  TableView: {
    Open: 'Open',
    Sort: 'Sort',
    OpenFilters: 'Open-Filters',
    CloseFilters: 'Close-Filters',
    UpdateFilters: 'Update-Filters',
    ResetFilters: 'Reset-Filters',
    ExportCSV: 'Export-CSV',
    DownloadReportsStart: 'Download-Reports-Start',
    DownloadReportsCancel: 'Download-Reports-Cancel',
    DownloadReportsFinish: 'Download-Reports-Finish',
  },
  SiteList: {
    AddFavorite: 'Add-Favorite',
    RemoveFavorite: 'Remove-Favorite',
    ClickSeeOnMap: 'Click-SeeOnMap',
    ClickSiteDetails: 'Click-Site-Details',
    DownloadReports: 'Download-Reports',
  },
  ObservationList: {
    ClickDownloadFiles: 'Click-Download-Files',
    ClickSeeOnMap: 'Click-SeeOnMap',
    ClickViewObservation: 'Click-View-Observation',
  },
  EmissionList: {
    ClickDownloadFiles: 'Click-Download-Files',
    ClickSeeOnMap: 'Click-SeeOnMap',
    ClickViewEmission: 'Click-View-Emission',
  },
  SiteDetails: {
    OpenObservations: 'Open-Observations',
    OpenTrendAnalysis: 'Open-TrendAnalysis',
  },
  TosModal: {
    ClickCancel: 'Click-Cancel',
    ClickAccept: 'Click-Accept',
  },
};
