/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React, { ReactNode } from "react";
import "./ModalWindow.scss";

interface Props {
  title: string;
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
  open: boolean;
  children?: ReactNode;
  buttonText?: string;
  onButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  disableBtn?: boolean;
}

const ModalWindow = ({
  title,
  className,
  size,
  open,
  children,
  buttonText,
  onButtonClick,
  disableBtn = false,
}: Props) => {
  let width: string = "inherit";
  if (size === "sm") {
    width = "480px";
  } else if (size === "md") {
    width = "750px";
  } else if (size === "lg") {
    width = "950px";
  } else if (size === "xl") {
    width = "1400px";
  }
  if (open) {
    return (
      <div className={className ? className : ""}>
        <div className="g-modal-root">
          <div className="g-modal-window">
            <div className="g-modal-window-header">
              <p>{title}</p>
            </div>
            <div className="g-modal-body" style={{ width: width }}>
              {children}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ModalWindow;
