/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
/** @format */

import React, { useState } from "react";
import {
  analyticsClient,
  EventActions,
  EventCategory,
} from "../../../core/monitoring/analytics";
import PublicPage from "../../components/PublicPage/PublicPage";
import { forgotPasswordSubmit, resendCode, resetPassword } from "../../../api/awsAuthApi";
import "./ForgotPassword.scss";
import LoginCreateBar from "../../components/LoginCreateBar/LoginCreateBar";
import CodeVerification from "../../components/Reusable/CodeVerification/CodeVerification";
import EmailVerification from "../../components/Reusable/EmailVerification/Emailverification";
import CreatePassword from "../../components/Reusable/CreatePassword/CreatePassword";
import { isPasswordComplexEnough } from "../../../core/utils/User.utils";
import { Locales } from "../../../core/constants/Localize";
import SuccessCreate from "../../components/SuccessCreate/SuccessCreate";
import {getCreateAccountEmail} from '../../../core/reducers/createAccount.slice';
import {useSelector} from 'react-redux';

const ForgotPassword = () => {
  const [hasError, setError] = useState<string | undefined>();
  const [isSuccess, setSuccess] = useState<boolean>(false);
  const [isEmailVerified, setEmailVerified] = useState<boolean>(false);
  const [isCodeSubmitted, setCodeSubmitted] = useState<boolean>(false);
  const email = useSelector(getCreateAccountEmail);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>("");

  async function handleCreatePasswordForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError(undefined);
    if (newPasswordConfirm === newPassword) {
      if (isPasswordComplexEnough(newPassword, email)) {
        await createPassword();
      } else {
        setError(Locales.YourPasswordIsNotComplexEnough);
      }
    } else {
      setError(Locales.YourPasswordsDoNotMatch);
    }
  }

  async function createPassword() {
    setIsLoading(true);
    try {
      await forgotPasswordSubmit(email, verificationCode, newPassword);
      setEmailVerified(false);
      setCodeSubmitted(false);
      setSuccess(true);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  function handleCodeVerificationForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setError(undefined);
    setCodeSubmitted(true);
  }

  async function handleResendCode() {
    setIsLoading(true);
    try {
      await resendCode(email);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleEmailVerificationForm(
    e: React.FormEvent<HTMLFormElement>
  ) {
    setError(undefined);
    e.preventDefault();

    analyticsClient.pushEvent({
      category: EventCategory.ForgotPassword,
      action: EventActions.ForgotPassword.Submit,
    });

    await sendEmailVerificationRequest();
  }

  async function sendEmailVerificationRequest() {
    setIsLoading(true);
    await resetPassword(email);
    setIsLoading(false);
    setEmailVerified(true);
  }

  const handleSetPassword = (value: string) => {
    setNewPassword(value);
    if (hasError !== undefined) {
      setError(undefined);
    }
  }

  const handleSetPasswordConfirm = (value: string) => {
    setNewPasswordConfirm(value);
    if (hasError !== undefined) {
      setError(undefined);
    }
  }

  return (
    <PublicPage>
      <div className="forgot-password">
        <LoginCreateBar isNewStickerShown />
        {isSuccess && <SuccessCreate text={Locales.NewPasswordSuccess} />}
        {!isSuccess && isCodeSubmitted && isEmailVerified && (
          <CreatePassword
            onSubmit={handleCreatePasswordForm}
            setPassword={handleSetPassword}
            setPasswordConfirm={handleSetPasswordConfirm}
            password={newPassword}
            passwordConfirm={newPasswordConfirm}
            isLoading={isLoading}
            showStep
            hasError={hasError}
          />
        )}
        {!isSuccess && !isCodeSubmitted && isEmailVerified && (
          <CodeVerification
            onSubmit={handleCodeVerificationForm}
            value={verificationCode}
            onChange={setVerificationCode}
            hasError={hasError}
            loading={isLoading}
            onResendCode={handleResendCode}
            showStep
          />
        )}
        {!isSuccess && !isCodeSubmitted && !isEmailVerified && (
          <>
            <h3 className="forgot-title">{Locales.ForgotYourPassword}</h3>
            <p className="instructions">{Locales.ForgotPasswordInstruction}</p>
            <EmailVerification
              email={email}
              showStep
              hasError={hasError}
              onSubmit={handleEmailVerificationForm}
              isLoading={isLoading}
              showPasswords={false}
            />
          </>
        )}
      </div>
    </PublicPage>
  );
};

export default ForgotPassword;
