/*
 * Copyright 2021 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import React, { createContext, useState } from "react";
import { CognitoAttributes } from "../interfaces/apiInterface";

// mapView, layers and highlightContext are mutable objects and best
// kept outside of the redux store
type Context = {
  userAttributes: CognitoAttributes | undefined;
  setUserAttributes: (attributes: CognitoAttributes | undefined) => void;
};

export const AppContext = createContext<Context>({
  userAttributes: undefined,
  setUserAttributes: () => undefined,
});

// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userAttributes, setUserAttributes] = useState<
    CognitoAttributes | undefined
  >(undefined);

  return (
    <AppContext.Provider
      value={{
        userAttributes,
        setUserAttributes,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
