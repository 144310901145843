/**
 * /*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

import { Locales } from '../constants/Localize';

interface Routes {
  [key: string]: {
    path: string;
    title: string;
  };
}

export const routes: Routes = {
  forgotPassword: {
    path: '/forgot-password',
    title: Locales['forgot-password'],
  },
  login: {
    path: '/',
    title: Locales.login,
  },
  createAccount: {
    path: '/create-account',
    title: Locales.createAccount,
  },
  logout: {
    path: '/logout',
    title: Locales.logout,
  },
  map: {
    path: '/map',
    title: Locales.map,
  },
  table: {
    path: '/table',
    title: Locales.table,
  },
  help: {
    path: '/help',
    title: Locales.Help,
  },
  home: {
    path: '/',
    title: Locales.home,
  },
  maintenance: {
    path: '/maintenance',
    title: Locales.maintenance,
  },
  resetPassword: {
    path: '/reset-password',
    title: Locales.ResetPassword,
  },
  siteDetails: {
    path: '/site-details',
    title: Locales.siteDetails,
  },
  styleguide: {
    path: '/styleguide',
    title: 'Styleguide',
  },
  invalidInviteLink: {
    path: '/invalid-invite',
    title: Locales.InvalidInviteLink.Title,
  },
  invalidPasswordResetLink: {
    path: '/invalid-password-reset',
    title: Locales.InvalidPasswordResetLink.Title,
  },
};
