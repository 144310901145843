/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Auth } from "aws-amplify";
import {
  NewUser,
  UpdateUser,
  SetUserTOS,
} from "../core/interfaces/apiInterface";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";
import AwsCookieStorage from "./AwsCookieStorage";
import awsconfig from "../aws-exports";

export async function signUp(newUser: NewUser) {
  return Auth.signUp(newUser);
}

export async function confirmSignUp(username: string, code: string) {
  return Auth.confirmSignUp(username, code);
}

export async function resetPassword(username: string) {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    console.log("error resetting password", error);
  }
}

export async function resendCode(username: string) {
  return await Auth.resendSignUp(username);
}

export async function forgotPasswordSubmit(
  username: string,
  verificationCode: string,
  newPassword: string
) {
  return await Auth.forgotPasswordSubmit(
    username,
    verificationCode,
    newPassword
  );
}

export const getCognitoUser = (username: string, keepLoggedIn: boolean) => {
  const UserPoolId = awsconfig["aws_user_pools_id"];
  const ClientId = awsconfig["aws_user_pools_web_client_id"];
  if (UserPoolId && ClientId) {
    let userPool = new CognitoUserPool({
      UserPoolId,
      ClientId,
      Storage: new AwsCookieStorage(keepLoggedIn) as any,
    });

    let userData = {
      Username: username,
      Pool: userPool,
      Storage: new AwsCookieStorage(keepLoggedIn) as any,
    };
    return new CognitoUser(userData);
  }
};

export function signInNew(
  username: string,
  password: string,
  keepLoggedIn: boolean,
  resolve: (value: unknown) => void,
  reject: (reason?: any) => void
) {
  let authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });
  let cognitoUser = getCognitoUser(username, keepLoggedIn);
  if (cognitoUser) {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: resolve,
      onFailure: reject,
    });
  }
}

export const getUserAttributes = () => {
  return Auth.currentUserInfo();
};

export async function signInPromise(
  username: string,
  password: string,
  keepLoggedIn: boolean = false
) {
  return new Promise(function (resolve, reject) {
    signInNew(username, password, keepLoggedIn, resolve, reject);
  });
}

export const updateUser = async (updateUser: UpdateUser) => {
  await signInPromise(updateUser.username, updateUser.password);
  let cognitoUser = await Auth.currentAuthenticatedUser();
  if (cognitoUser) {
    await Auth.updateUserAttributes(cognitoUser, updateUser.attributes);
  }
};

export const setUserTOSDate = async (TOS: SetUserTOS) => {
  let cognitoUser = await Auth.currentAuthenticatedUser();
  if (cognitoUser) {
    await Auth.updateUserAttributes(cognitoUser, TOS.attributes);
  }
};
