/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./app/assets/styles/Spectra.scss";
import ForgotPassword from "./app/pages/ForgotPassword/ForgotPassword";
import CreateAccount from "./app/pages/CreateAccount/CreateAccount";
import Login from "./app/pages/Login/Login";
import { routes } from "./core/routes/Routes";
import { AppContextProvider } from "./core/context/app.context";
import { Provider } from "react-redux";
import { store } from "./core/reducers/store";

function App() {
  return (
    <Provider store={store}>
      <AppContextProvider>
        <Router>
          <Routes>
            <Route path={routes.login.path} element={<Login />} />
            <Route path={routes.createAccount.path}>
              <Route index element={<CreateAccount />} />
              <Route path=":option" element={<CreateAccount />} />
            </Route>
            <Route
              path={routes.forgotPassword.path}
              element={<ForgotPassword />}
            />
          </Routes>
        </Router>
      </AppContextProvider>
    </Provider>
  );
}

export default App;
