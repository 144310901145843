/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */
import { ENV } from "../constants/Constants";

const getUrl = (environment: ENV) => {
  if (environment === ENV.Production) {
    return process.env.REACT_APP_PROD_BASIC_URL ?? "";
  }
  if (environment === ENV.Staging) {
    return process.env.REACT_APP_STAGING_BASIC_URL ?? "";
  }
  if (environment === ENV.Development) {
    return process.env.REACT_APP_DEV_BASIC_URL ?? "";
  }
  if (environment === ENV.Local) {
    return process.env.REACT_APP_LOCALDEV_BASIC_URL ?? "";
  }
  return "";
};

export default getUrl;
