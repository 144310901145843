/**
 * /*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

/**
 * @description password must be 15 characters in length, must contain at least one letter
 * & cannot be the same as your email
 */
export function isPasswordComplexEnough(password: string, userEmail?: string) {
  const isLongEnough = password.length >= 15;
  const containsLowercase = /[a-z]/.test(password);
  const containsUppercase = /[A-Z]/.test(password);
  const containsNumber = /[0-9]/.test(password);
  const isNotEmail = userEmail ? password !== userEmail : true;
  return (
    isLongEnough &&
    containsLowercase &&
    containsUppercase &&
    containsNumber &&
    isNotEmail
  );
}
