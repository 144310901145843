/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

import TextField, { AUTOCOMPLETE } from "../Reusable/TextField/TextField";
import { Locales } from "../../../core/constants/Localize";
import TextButton from "../Reusable/TextButton/TextButton";
import React, { FormEvent } from "react";
import "./CreateAccountForm.scss";

export interface LocalProps {
  showStep: boolean;
  hasError: string | undefined;
  onSubmit: (newValue: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  firstName: string;
  lastName: string;
  title: string;
  business: string;
  industry: string;
  setFirstName: (value: string) => void;
  setLastName: (value: string) => void;
  setTitle: (value: string) => void;
  setBusiness: (value: string) => void;
  setIndustry: (value: string) => void;
}

const AddAttributesToAccountForm = ({
  showStep,
  hasError,
  onSubmit,
  isLoading,
  firstName,
  lastName,
  title,
  business,
  industry,
  setFirstName,
  setLastName,
  setTitle,
  setBusiness,
  setIndustry,
}: LocalProps) => {
  const setSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setIndustry(value);
  };
  let all_values_set = firstName && lastName && title && business && industry;
  const industryOptions = [
    "Academic & Research",
    "Agriculture",
    "Aluminium & Steel",
    "Cement",
    "Chemicals",
    "Coal Mining",
    "Finance",
    "Government",
    "Media",
    "NGO",
    "Oil & Gas",
    "Power Generation",
    "Services - Environmental & GIS",
    "Student",
    "Transportation",
    "Waste Management",
    "Other",
  ];

  return (
    <form className="create-account-form" onSubmit={(e) => onSubmit(e)}>
      <h3 className="details">{Locales.MandatoryFields}</h3>
      <TextField
        id="first-name"
        autocomplete={AUTOCOMPLETE.FIRST_NAME}
        placeholder={Locales.FirstName + "*"}
        value={firstName}
        onChange={setFirstName}
        required
      />
      <TextField
        id="last-name"
        autocomplete={AUTOCOMPLETE.LAST_NAME}
        placeholder={Locales.LastName + "*"}
        value={lastName}
        onChange={setLastName}
        required
      />
      <TextField
        id="title_field"
        placeholder={Locales.JobTitle + "*"}
        value={title}
        onChange={setTitle}
        required
      />
      <TextField
        id="business"
        autocomplete={AUTOCOMPLETE.ORGANIZATION}
        placeholder={Locales.Business + "*"}
        value={business}
        onChange={setBusiness}
        required
      />
      <select
        id="industry"
        className={"g-select-field drop-down"}
        value={industry}
        onChange={setSelectChange}
        required
      >
        <option value="" disabled hidden>
          {Locales.Industry}*
        </option>
        {industryOptions.map((industry, k) => (
          <option key={k} value={industry}>
            {industry}
          </option>
        ))}
      </select>
      <div className="errors-container">
        {hasError && <p className="p-error">{hasError}</p>}
      </div>
      <div className="submit-container" style={{ marginBottom: 10 }}>
        <TextButton
          type="filled"
          loading={isLoading}
          disabled={isLoading || !all_values_set}
          submit
        >
          {Locales.CreateAccount}
        </TextButton>
      </div>
      {showStep && <div className="submit-container">Step 3/3</div>}
    </form>
  );
};

export default AddAttributesToAccountForm;
