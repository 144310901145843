/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

import PasswordField from "../PasswordField/PasswordField";
import { AUTOCOMPLETE } from "../TextField/TextField";
import { Locales } from "../../../../core/constants/Localize";
import Tooltip from "../Tooltip/Tooltip";
import PasswordHint from "../../PasswordHint/PasswordHint";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import TextButton from "../TextButton/TextButton";
import React, { FormEvent } from "react";

export interface LocalProps {
  password: string;
  passwordConfirm: string;
  showStep: boolean;
  hasError: string | undefined;
  setPassword: (newValue: string) => void;
  setPasswordConfirm: (newValue: string) => void;
  onSubmit: (newValue: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
}

const CreatePassword = (
  {
    onSubmit,
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    hasError,
    showStep,
    isLoading,
  }: LocalProps) => (
  <form onSubmit={(e) => onSubmit(e)}>
    <h3 style={{ marginLeft: "1rem", fontSize: "1rem", marginTop: "2rem" }}>{Locales.CreateNewPassword}</h3>
    <div className="field-and-tooltip">
      <PasswordField
        id="new-password"
        name="password"
        autocomplete={AUTOCOMPLETE.NEW_PASSWORD}
        placeholder={Locales.NewPassword}
        value={password}
        onChange={setPassword}
        className="login-field"
        style={{ marginBottom: 30 }}
        required
      />
      <Tooltip size="md" content={<PasswordHint />} placement="top">
        <div className="icon-right">
          <InfoIcon className="info-icon" />
        </div>
      </Tooltip>
    </div>
    <PasswordField
      id="confirm-password"
      name="passwordConfirm"
      autocomplete={AUTOCOMPLETE.NEW_PASSWORD}
      placeholder={Locales.ConfirmNewPassword}
      value={passwordConfirm}
      onChange={setPasswordConfirm}
      className="login-field"
      style={{ marginBottom: 0 }}
      required
    />
    <div className="errors-container">
      {hasError && <p className="p-error">{hasError}</p>}
    </div>
    <div className="submit-container" style={{ marginBottom: 10 }}>
      <TextButton
        type="filled"
        loading={isLoading}
        disabled={isLoading}
        submit
      >
        {Locales.SaveNewPassword}
      </TextButton>
    </div>
    {
      showStep &&
        <div className="submit-container">{Locales.Step3}</div>
    }
  </form>
)

export default CreatePassword