/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

import React, { useState } from "react";
import TextField from "../TextField/TextField";
import { LocalProps } from "../TextField/TextField";
import { ReactComponent as EyeSvg } from "../../../assets/icons/eye.svg";
import { ReactComponent as EyeSlashSvg } from "../../../assets/icons/eye-slash.svg";

const PasswordField = (props: LocalProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  function togglePasswordVisibility() {
    setShowPassword(!showPassword)
  }

  return (
    <TextField
      {...props}
      hideInput={!showPassword}
      rightIcon={showPassword ? <EyeSvg /> : <EyeSlashSvg />}
      iconClick={togglePasswordVisibility}
    />
  );
};

export default PasswordField;
