/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

interface CreateAccountSlice {
  createAccountIsSuccess: boolean;
  createAccountIsEmailVerified: boolean;
  createAccountIsCodeSubmitted: boolean;
  createAccountEmail: string;
  createAccountPassword: string;
}

const initialState: CreateAccountSlice = {
  createAccountIsSuccess: false,
  createAccountIsEmailVerified: false,
  createAccountIsCodeSubmitted: false,
  createAccountEmail: '',
  createAccountPassword: '',
}

export const createAccountSlice = createSlice({
  name: 'createAccount',
  initialState,
  reducers: {
    setCreateAccountIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.createAccountIsSuccess = action.payload;
    },
    setCreateAccountIsEmailVerified: (state, action: PayloadAction<boolean>) => {
      state.createAccountIsEmailVerified = action.payload;
    },
    setCreateAccountIsCodeSubmitted: (state, action: PayloadAction<boolean>) => {
      state.createAccountIsCodeSubmitted = action.payload;
    },
    setCreateAccountEmail: (state, action: PayloadAction<string>) => {
      state.createAccountEmail = action.payload;
    },
    setCreateAccountPassword: (state, action: PayloadAction<string>) => {
      state.createAccountPassword = action.payload;
    },
  }
});

export const { setCreateAccountIsSuccess, setCreateAccountIsEmailVerified, setCreateAccountIsCodeSubmitted, setCreateAccountEmail, setCreateAccountPassword} = createAccountSlice.actions;
export const getCreateAccountIsSuccess = (state:RootState) => state.createAccount.createAccountIsSuccess;
export const getCreateAccountIsEmailVerified = (state:RootState) => state.createAccount.createAccountIsEmailVerified;
export const getCreateAccountIsCodeSubmitted = (state:RootState) => state.createAccount.createAccountIsCodeSubmitted;
export const getCreateAccountEmail = (state:RootState) => state.createAccount.createAccountEmail;
export const getCreateAccountPassword = (state:RootState) => state.createAccount.createAccountPassword;

export default createAccountSlice.reducer;