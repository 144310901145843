/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

import { Locales } from "../../../../core/constants/Localize";
import TextField, { AUTOCOMPLETE } from "../TextField/TextField";
import TextButton from "../TextButton/TextButton";
import React, { FormEvent, ReactNode } from "react";
import PasswordField from "../PasswordField/PasswordField";
import Tooltip from "../Tooltip/Tooltip";
import PasswordHint from "../../PasswordHint/PasswordHint";
import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import "./EmailVerification.scss";
import {
  setCreateAccountEmail,
  setCreateAccountPassword,
} from "../../../../core/reducers/createAccount.slice";
import { useDispatch } from "react-redux";

export interface LocalProps {
  email: string;
  showStep: boolean;
  hasError: string | undefined;
  onSubmit: (newValue: FormEvent<HTMLFormElement>) => void;
  isLoading: boolean;
  showPasswords: boolean;
  afterField?: ReactNode;
  beforeStep?: ReactNode;
  password?: string;
  passwordConfirm?: string;
  setPasswordConfirm?: (newValue: string) => void;
}

const EmailVerification = ({
  email,
  onSubmit,
  hasError,
  isLoading,
  showStep,
  afterField,
  beforeStep,
  showPasswords,
  password,
  passwordConfirm,
  setPasswordConfirm,
}: LocalProps) => {
  const dispatch = useDispatch();
  const handleSetPassword = (value: string) => {
    dispatch(setCreateAccountPassword(value));
  };
  const handleSetPasswordConfirm = (value: string) => {
    if (setPasswordConfirm) {
      setPasswordConfirm(value);
    }
  };

  const handleSetEmail = (value: string) => {
    dispatch(setCreateAccountEmail(value));
  };

  return (
    <form onSubmit={(e) => onSubmit(e)}>
      <TextField
        id="forgot-password-email"
        name="email"
        type="email"
        autocomplete={AUTOCOMPLETE.EMAIL}
        className="login-field"
        style={{ marginBottom: 0 }}
        placeholder={Locales.Email}
        value={email}
        onChange={handleSetEmail}
        required
      />
      {showPasswords && (
        <>
          <div className="field-and-tooltip">
            <PasswordField
              id="new-password"
              name="password"
              autocomplete={AUTOCOMPLETE.NEW_PASSWORD}
              placeholder={Locales.Password + "*"}
              value={password ?? ""}
              onChange={handleSetPassword}
              className="login-field"
              required
            />
            <Tooltip size="md" content={<PasswordHint />} placement="top">
              <div className="icon-right">
                <InfoIcon className="info-icon" />
              </div>
            </Tooltip>
          </div>
          <PasswordField
            id="confirm-password"
            name="passwordConfirm"
            autocomplete={AUTOCOMPLETE.NEW_PASSWORD}
            placeholder={Locales.ConfirmPassword + "*"}
            value={passwordConfirm ?? ""}
            onChange={handleSetPasswordConfirm}
            className="login-field"
            style={{ marginBottom: 0 }}
            required
          />
        </>
      )}
      <div className="create-account-errors-container">
        {hasError && <p className="p-error">{hasError}</p>}
      </div>
      {afterField && afterField}
      <div className="submit-container" style={{ marginBottom: 10 }}>
        <TextButton
          type="filled"
          loading={isLoading}
          disabled={isLoading}
          submit
        >
          {Locales.VerifyEmail}
        </TextButton>
      </div>
      {beforeStep && beforeStep}
      {showStep && <div className="submit-container step">{Locales.Step1}</div>}
    </form>
  );
};

export default EmailVerification;

