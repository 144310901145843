/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React from "react";
import { ANIMATION_DURATION } from "../../../../core/constants/Constants";
import "./Spinner.scss";

interface LocalProps {
  size?: number;
  strokeWidth?: number;
  color?: string;
  duration?: number;
  className?: string;
  style?: React.CSSProperties;
}

const Spinner = ({ size=32, strokeWidth=1, color="#000000", duration=ANIMATION_DURATION, className, style }: LocalProps) => {
  const half = size/2;
  
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className="g-spinner"
    >
      <title>info</title>
      <circle
        cx={half}
        cy={half}
        r={half - 1}
        strokeDasharray={size}
        strokeWidth={strokeWidth}
        stroke={color}
        fill="none"
        style={{ animationDuration: `${duration}s`, transformOrigin: `${half}px ${half}px`}}
      />
    </svg>
  );
};

export default Spinner;
