/**
 * Copyright 2023 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import Cookies from "js-cookie";
import { HubspotFormSubmission } from "../core/interfaces/apiInterface";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
export const sendCompletedFormToHubspot = async (
  body: HubspotFormSubmission
): Promise<boolean> => {
  const portalId = "22487970";
  const formId = "32560561-7a7e-4d3e-8a06-619e9e3124d4";
  while (!Cookies.get("hubspotutk")) {
    await sleep(100);
  }
  const token = Cookies.get("hubspotutk") ?? null;
  const url = `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}/`;
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ...body, context: { hutk: token } }),
  };
  const response = await fetch(url, options);
  if (response.status === 200) {
    return true;
  } else {
    const error = await response.json();
    let message = "Unknown error in form submission";
    if (error.message) {
      ({ message } = error);
      if (error.errors && error.errors.length > 0) {
        const [firstError] = error.errors;
        if (firstError.errorType === "BLOCKED_EMAIL") {
          // User must use an email that is not free
          return false;
        }
        message += `. ${firstError.message}`;
      }
    }
    throw Error(message);
  }
};
