/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

const externalRedirect = (url: string, queryParams: string | null = null) => {
  if (queryParams) {
    window.location.href = url + "?" + queryParams;
  } else {
    window.location.href = url;
  }
};
export default externalRedirect;
