/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Locales } from "../../../core/constants/Localize";
import "./ScheduledMaintenanceWarning.scss";

const ScheduleMaintenanceWarning = () => {
  return (
    <div className="schedule-maintenance-warning">
      <header className="header">
        <h3 className="title">{Locales.ScheduledMaintenance?.Title}</h3>
      </header>
      <section className="content">
        <p className="info">{Locales.ScheduledMaintenance?.Item1}</p>
      </section>
      {Locales.ScheduledMaintenance?.Item2 !== "" && (
        <section className="content">
          <p className="info">{Locales.ScheduledMaintenance?.Item2}</p>
        </section>
      )}
      {Locales.ScheduledMaintenance?.Item3 !== "" && (
        <section className="content">
          <p className="info">{Locales.ScheduledMaintenance?.Item3}</p>
        </section>
      )}
    </div>
  );
};

export default ScheduleMaintenanceWarning;
