/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */
import { ENV } from "../constants/Constants";

const getEnv = () => {
  const host = window.location.hostname;
  let environment = ENV.Local;
  if (host === "login.ghgsat.com") {
    environment = ENV.Production;
  } else if (host === "login-staging.ghgsat.com") {
    environment = ENV.Staging;
  } else if (host === "login-dev.ghgsat.com") {
    environment = ENV.Development;
  }
  return environment;
};

export default getEnv;
