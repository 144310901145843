/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Locales } from "../../../core/constants/Localize";
import "./HeroSection.scss";
import { ReactComponent as WhiteArrowSvg } from "../../assets/icons/whiteArrow.svg";
import HeroImage from "../../assets/images/hero-image.jpg";

const HeroSection = () => (
  <section className="public-page-hero-header">
    <p className="public-page-pre-title">{Locales.LoginCop27.PreTitle}</p>
    <h1 className="public-page-title">{Locales.LoginCop27.Title}</h1>
    <p className="public-page-green-description">
      {Locales.LoginCop27.SeeEmissions}
    </p>
    <p className="public-page-description">
      {Locales.LoginCop27.ToMitigateThem}
    </p>
    <div className="public-page-white-arrow">
      <WhiteArrowSvg />
    </div>
    <img className="public-page-image" src={HeroImage} alt="emission hero" />
  </section>
);

export default HeroSection;
