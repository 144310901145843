/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import createAccountSlice from "./createAccount.slice";

export const store = configureStore({
  reducer: {
    createAccount: createAccountSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void>= ThunkAction<ReturnType, RootState, unknown, Action<string>>;