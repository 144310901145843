/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import React, { ReactNode } from "react";
import { Locales } from "../../../../core/constants/Localize";
import "./TextField.scss";

// See https://devdocs.io/html/attributes/autocomplete for full list
export enum AUTOCOMPLETE {
  NAME = "name",
  FIRST_NAME = "given-name",
  LAST_NAME = "family-name",
  EMAIL = "email",
  CODE = "code",
  USERNAME = "username",
  PASSWORD = "current-password",
  NEW_PASSWORD = "new-password",
  ORGANIZATION = "organization",
}

export interface LocalProps {
  id: string;
  value: string;
  label?: string;
  type?: React.HTMLInputTypeAttribute;
  name?: string;
  autocomplete?: AUTOCOMPLETE;
  helpText?: string;
  rightIcon?: ReactNode;
  iconClick?: Function;
  className?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  noPlaceholder?: boolean;
  hideInput?: boolean;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  valid?: boolean;
  altered?: boolean;
  size?: "sm" | "md";
  onChange: (newValue: string) => void;
}

const TextField = (
  {
    id,
    value,
    label,
    type,
    name,
    helpText,
    rightIcon,
    iconClick,
    className,
    style,
    placeholder,
    noPlaceholder,
    hideInput,
    required,
    error,
    disabled,
    valid,
    altered,
    size,
    onChange,
  }: LocalProps) => {
  return (
    <div
      style={style}
      className={`field g-input-text ${className ? className : ""} ${
        altered ? "altered" : ""
      } ${disabled ? "disabled" : ""}  ${valid ? "valid" : ""} ${
        error ? "error" : ""
      } ${size ? size : ""}`}
    >
      {label && (
        <label id={`${id}-label`} htmlFor={id} className="block g-label">
          {label}
        </label>
      )}

      <input
        id={id}
        name={name}
        autoComplete={name}
        type={hideInput ? "password" : type ? type : "text"}
        className={`block g-text-field ${rightIcon ? "right-icon" : ""}`}
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        placeholder={
          noPlaceholder ? "" : placeholder ? placeholder : Locales.PleaseEnter
        }
        required={required}
        aria-required={required ?? false}
        aria-invalid={required && value === ""}
        aria-describedby={`${id}-label`}
      />
      {helpText && (
        <small id={`${id}-help`} className="help-text">
          {helpText}
        </small>
      )}
      {rightIcon && <span onClick={() => iconClick && iconClick()} className="g-field-end-icon">{rightIcon}</span>}
    </div>
  );
};

export default TextField;
