/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { captureMessage, Severity } from '@sentry/react';

function getCookie(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
export const trace = (tag: string) => (x: any) => {
  console.table(tag, x);
  return x;
};

// Add a leading 0
function addZeroBefore(n: number) {
  return (n < 10 ? '0' : '') + n;
}

/**
 * @description if cookie debug exist, log the info in console
 * @param log
 */
export function info(log: any) {
  const infoEnable = getCookie('debug');
  const date = new Date();
  const seconds = date.getSeconds();
  const minutes = date.getMinutes();
  const hour = date.getHours();
  if (infoEnable) {
    console.info(`[Info]: `, log, `🕒 ${hour}:${addZeroBefore(minutes)}:${addZeroBefore(seconds)}`);
  }
}

export function getErrorString(err: unknown) {
  return JSON.stringify(err, Object.getOwnPropertyNames(err));
}

export function logError(message: string) {
  const errorMessage = `[SPECTRA ERROR]: ${message}`;
  console.error(errorMessage);
  captureMessage(errorMessage, Severity.Error);
}

export function logGraphQLError(queryId: string, error: unknown, dataRetrieved: boolean) {
  let message = `GraphQL query for ${queryId} threw error (${getErrorString(error)})`;
  if (dataRetrieved) {
    message = message + '; Some data still successfully loaded to the card.';
  }
  console.error(message);
  captureMessage(message, Severity.Error);
}
