/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

import { Locales } from "../../../../core/constants/Localize";
import TextField, { AUTOCOMPLETE } from "../TextField/TextField";
import TextButton from "../TextButton/TextButton";
import React, { FormEvent } from "react";
import "./CodeVerification.scss";

export interface LocalProps {
  value: string;
  showStep: boolean;
  hasError: string | undefined;
  onResendCode: () => void;
  onChange: (newValue: string) => void;
  onSubmit: (newValue: FormEvent<HTMLFormElement>) => void;
  loading: boolean;
}

const CodeVerification = ({
  onSubmit,
  value,
  onChange,
  onResendCode,
  loading,
  hasError,
  showStep,
}: LocalProps) => (
  <form onSubmit={(e) => onSubmit(e)}>
    <p className="instructions">{Locales.VerificationCodeInstructions}</p>
    <TextField
      id="verification_code_field"
      name="verification-code"
      type="text"
      autocomplete={AUTOCOMPLETE.CODE}
      className="login-field"
      style={{ marginBottom: 0 }}
      placeholder={Locales.VerificationCode}
      value={value}
      onChange={onChange}
      required
    />
    <div className="resend-code-container ">
      <TextButton
        type="link-styled"
        style={{ fontWeight: 'bold' }}
        onClick={onResendCode}
      >
        {Locales.SendNewVerificationCode}
      </TextButton>
    </div>
    <div className="errors-container">
      {hasError && <p className="p-error">{hasError}</p>}
    </div>
    <div className="submit-container" style={{ marginBottom: 10 }}>
      <TextButton
        type="filled"
        loading={loading}
        disabled={loading || !value}
        submit
      >
        {Locales.Validate_Arrow}
      </TextButton>
    </div>
    {showStep && <div className="submit-container">{Locales.Step2}</div>}
  </form>
);

export default CodeVerification;
