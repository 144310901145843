/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { ENV } from "../constants/Constants";
import getEnv from "./environment";

const getCookieDomain = () => {
  const environment = getEnv();
  if (environment === ENV.Production) {
    return process.env.REACT_APP_PRODUCTION_COOKIE_DOMAIN ?? "";
  }
  if (environment === ENV.Staging) {
    return process.env.REACT_APP_STAGING_COOKIE_DOMAIN ?? "";
  }
  if (environment === ENV.Development) {
    return process.env.REACT_APP_DEV_COOKIE_DOMAIN ?? "";
  }
  if (environment === ENV.Local) {
    return process.env.REACT_APP_LOCALDEV_COOKIE_DOMAIN ?? "";
  }
  return "";
};

export default getCookieDomain;
