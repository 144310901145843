/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import Cookies from "js-cookie";
import {
  AWS_COOKIE_PREFIX,
  AWS_COOKIE_DOMAIN,
} from "../core/constants/Constants";
import { ICognitoStorage } from "amazon-cognito-identity-js";

export default class AwsCookieStorage implements ICognitoStorage {
  keepLoggedIn: boolean;

  constructor(keepLoggedIn: boolean) {
    this.keepLoggedIn = keepLoggedIn;

    Cookies.set("keep_logged_in", this.keepLoggedIn.toString(), {
      domain: AWS_COOKIE_DOMAIN,
      sameSite: "None",
      secure: true,
      expires: this.keepLoggedIn ? 14 : undefined, // in days
    });
  }

  public setItem(key: string, value: string): void {
    Cookies.set(AWS_COOKIE_PREFIX + key, value, {
      domain: AWS_COOKIE_DOMAIN,
      sameSite: "None",
      secure: true,
      expires: this.keepLoggedIn ? 14 : undefined, // in days
    });
  }

  public getItem(key: string): string | null {
    return Cookies.get(AWS_COOKIE_PREFIX + key) ?? null;
  }

  public removeItem(key: string): void {
    Cookies.remove(AWS_COOKIE_PREFIX + key);
  }

  public clear(): void {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      if (cookieName.includes(AWS_COOKIE_PREFIX)) {
        Cookies.remove(cookieName);
      }
    });
  }
}
