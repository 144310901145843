/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { init } from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Auth } from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom/client";
import { hotjar } from "react-hotjar";
import AwsCookieStorage from "./api/AwsCookieStorage";
import App from "./App";
import awsconfig from "./aws-exports";
import { analyticsClient } from "./core/monitoring/analytics";
import reportWebVitals from "./reportWebVitals";
import getEnv from "./core/utils/environment";
import { ENV } from "./core/constants/Constants";

Auth.configure({
  ...awsconfig,
  storage: new AwsCookieStorage(false),
});

const environment = getEnv();

init({
  dsn: "https://fe0122b634ed430eb6c4c47d24c9285b@1511-sentry.ghgsat.com/8",
  integrations: [new Integrations.BrowserTracing()],
  environment,
  maxValueLength: 5000,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  enabled: environment !== ENV.Development  && environment !== ENV.Local,
});

if (environment === ENV.Development || environment === ENV.Local) {
  analyticsClient.initializeDevMode();
} else {
  analyticsClient.initialize("G-7ZLTMXK38D");
}

const isHotjarEnabled = Boolean(process.env.REACT_APP_HOTJAR_ENABLED);
const hotjarId = Number(process.env.REACT_APP_HOTJAR_ID);
const hotjarVersion = Number(process.env.REACT_APP_HOTJAR_VERSION) || 6;

if (isHotjarEnabled && hotjarId) {
  hotjar.initialize(hotjarId, hotjarVersion);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
