/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import getCookieDomain from "../utils/cookieDomain";

export enum LANG {
  en = "en",
  fr = "fr",
}

export enum ENV {
  Local = 'local',
  Development = 'development',
  Staging = 'staging',
  Production = 'production',
}

export enum NAV_OPTIONS {
  Verified = 'verified',
  Unverified = 'unverified',
}

export const PRIVACY_POLICY_URL = "https://www.ghgsat.com/en/privacy-policy/";

export const AWS_COOKIE_PREFIX = "spectra-aws";
export const AWS_COOKIE_DOMAIN = getCookieDomain();

export const ANIMATION_DURATION = 0.8;
