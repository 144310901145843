/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { Locales } from '../../../core/constants/Localize';
import './PasswordHint.scss';

const PasswordHint = () => {
  return (
    <ul className="password-hint">
      {Locales.PasswordHints.map((hint: string, i: any) => (
        <li key={i}>{hint}</li>
      ))}
    </ul>
  );
};

export default PasswordHint;
