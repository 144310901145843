/**
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { FormEvent, useContext, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import {
  getUserAttributes,
  resendCode,
  setUserTOSDate,
  signInPromise,
} from "../../../api/awsAuthApi";
import { Locales } from "../../../core/constants/Localize";
import { routes } from "../../../core/routes/Routes";
import PublicPage from "../../components/PublicPage/PublicPage";
import TextButton from "../../components/Reusable/TextButton/TextButton";
import TextField, {
  AUTOCOMPLETE,
} from "../../components/Reusable/TextField/TextField";
import "./Login.scss";
import TOSModal from "../../components/TOSModal/TOSModal";
import LoginCreateBar from "../../components/LoginCreateBar/LoginCreateBar";
import PasswordField from "../../components/Reusable/PasswordField/PasswordField";
import getEnv from "../../../core/utils/environment";
import externalRedirect from "../../../core/routes/ExternalRedirect";
import getUrl from "../../../core/utils/urls";
import { AppContext } from "../../../core/context/app.context";
import { CognitoAttributes } from "../../../core/interfaces/apiInterface";
import { NAV_OPTIONS } from "../../../core/constants/Constants";
import { sendCompletedFormToHubspot } from "../../../api/hubspotApi";

const Login = () => {
  const [hasError, setError] = useState<string | undefined>();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isToSLoading, setIsToSLoading] = useState<boolean>(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState<boolean>(false);
  const [TOSModalOpen, setTOSModalOpen] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const { setUserAttributes } = useContext(AppContext);
  const navigate = useNavigate();
  const next = searchParams.get("next");

  const basicUrl = getUrl(getEnv());

  async function handleLoginFormSubmit(e: FormEvent) {
    e.preventDefault();
    setError(undefined);
    setIsLoading(true);
    try {
      let result = await signInPromise(email, password, keepLoggedIn);
      if (result) {
        try {
          let results = await getUserAttributes();
          if (results?.attributes) {
            const attributes = results.attributes as CognitoAttributes;
            const requiredFields =
              !!attributes.given_name &&
              !!attributes.family_name &&
              !!attributes["custom:business"] &&
              !!attributes["custom:job_position"] &&
              !!attributes["custom:industry"];

            if (!requiredFields) {
              setUserAttributes({ ...attributes, password });
              navigate(routes.createAccount.path + "/" + NAV_OPTIONS.Verified);
            } else if (!attributes["custom:tos_accepted"]) {
              setTOSModalOpen(true);
            } else {
              externalRedirect(basicUrl, next);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    } catch (e: any) {
      if (e.code === "UserNotConfirmedException") {
        await resendCode(email);
        setUserAttributes({ email, password, email_verified: false });
        navigate(routes.createAccount.path + "/" + NAV_OPTIONS.Unverified);
      }
      console.log(e);
      setError(e.message);
    }
    setIsLoading(false);
  }

  const handleKeepLoggedInChecked = () => {
    setKeepLoggedIn(!keepLoggedIn);
  };

  const handlePassword = (passwordString: string) => {
    setError(undefined);
    setPassword(passwordString);
  };

  const sendInfoToHubSpot = async (attributes: CognitoAttributes) => {
    const body = {
      fields: [
        {
          name: "Email",
          value: attributes.email ?? "",
        },
        {
          name: "First Name",
          value: attributes.given_name ?? "",
        },
        {
          name: "Last Name",
          value: attributes.family_name ?? "",
        },
        {
          name: "Job Title",
          value: attributes["custom:job_position"] ?? "",
        },
        {
          name: "Company",
          value: attributes["custom:business"] ?? "",
        },
        {
          name: "Industry",
          value: attributes["custom:industry"] ?? "",
        },
        {
          name: "SPECTRA TOS Accepted",
          value: attributes["custom:tos_accepted"] ?? "",
        },
      ],
    };
    try {
      await sendCompletedFormToHubspot(body);
    } catch (e: any) {
      if (e.message) {
        console.error(e.message);
      }
    }
  };

  function insertHubSpotScript() {
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "hs-script-loader";
    script.src = "//js.hs-scripts.com/22487970.js";
    document.body.appendChild(script);
  }

  const handleAcceptTOS = async () => {
    insertHubSpotScript();
    setIsToSLoading(true);
    try {
      await setUserTOSDate({
        attributes: { "custom:tos_accepted": new Date().getTime().toString() },
      });
      let result = await getUserAttributes();
      if (result?.attributes) {
        const attributes = result.attributes as CognitoAttributes;
        await sendInfoToHubSpot(attributes);
        externalRedirect(basicUrl, next);
      }
    } catch (e: any) {
      setError(e.message);
      setIsToSLoading(false);
    }
  };

  return (
    <PublicPage>
      <div className="login">
        <LoginCreateBar isNewStickerShown />
        <div className="customer-login-prompt">
          {Locales.formatString(Locales.CustomerLoginPrompt, {
            here: (
              <a className="login-here" href="https://spectra.ghgsat.com">
                {Locales.Here}
              </a>
            ),
          })}
        </div>
        <form onSubmit={handleLoginFormSubmit}>
          <TextField
            id="login-email"
            name="email"
            type="email"
            autocomplete={AUTOCOMPLETE.EMAIL}
            className="login-field"
            style={{ marginBottom: 10, marginTop: "1.5rem" }}
            placeholder={Locales.Email}
            value={email}
            onChange={setEmail}
            required
          />
          <PasswordField
            id="login-password"
            name="password"
            autocomplete={AUTOCOMPLETE.PASSWORD}
            className="login-field"
            style={{ marginBottom: 10 }}
            placeholder={Locales.Password}
            value={password}
            onChange={handlePassword}
            required
          />

          <NavLink
            title={Locales.IForgotMyPassword}
            className="g-link forgot-password-link"
            to={routes.forgotPassword.path}
          >
            {Locales.IForgotMyPassword}
          </NavLink>
          <div className="errors-container">
            {hasError && <p className="p-error">{hasError}</p>}
          </div>
          <div className="submit-container">
            <TextButton
              type="filled"
              loading={isLoading}
              disabled={isLoading}
              submit
            >
              {Locales.LogIn}
            </TextButton>
          </div>
          <div className="keep-logged-in">
            <label htmlFor="keep-logged-in">
              <input
                id={"keep-logged-in"}
                type={"checkbox"}
                checked={keepLoggedIn}
                onChange={handleKeepLoggedInChecked}
              />
              {Locales.KeepLoggedIn}
            </label>
          </div>
        </form>
        {TOSModalOpen && (
          <TOSModal
            open={TOSModalOpen}
            loading={isToSLoading}
            accept={handleAcceptTOS}
            cancel={setTOSModalOpen}
          />
        )}
      </div>
    </PublicPage>
  );
};

export default Login;
